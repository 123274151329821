.page-section {
  position: relative;
}

.page-section__nav {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  width: var(--ws-l);
  height: 100vh;
  height: calc(100 * var(--vh, 1vh));
  box-sizing: border-box;
}

.no-js .page-section__nav {
  display: none;
}

.page-section__nav__link {
  position: absolute;
  top: auto;
  right: calc((var(--ws-l) - 0.8rem) / 2);
  bottom: var(--ws);
  display: block;
  height: 0.8rem;
  padding: 0;
  text-decoration: none;
  background: transparent;
  border: 0;
  transform: rotate(90deg);
}

.page-section__nav__link::after {
  content: none;
}

.page-section__nav__previous {
  top: var(--ws);
  transform: rotate(-90deg);
}

.page-section__nav__icon {
  display: block;
  width: auto;
  height: 100%;
}

.main__nav {
  position: fixed;
  top: var(--ws);
  left: var(--ws);
  margin-bottom: calc(var(--logo-height) * 1px);
}

.main__nav__link {
  display: flex;
  align-items: center;
  text-shadow: none;
  filter: drop-shadow(1px 3px 2px var(--black));
}

.main__nav__link::after {
  content: none;
}

.main__nav__logo__image {
  display: inline-block;
  width: auto;
  height: calc(var(--logo-height) * 1px);
  margin: 0 0 0 1rem;
}

.main__nav__icon {
  display: inline-block;
  height: var(--ws);
}
