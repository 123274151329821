.textcolumn {
  max-width: 35em;
  margin: var(--ws-xl) var(--ws);
  line-height: 1.4;
}

@media (min-width: 750px) {
  .textcolumn {
    font-size: 1.25em;
    line-height: 1.5;
  }
}
