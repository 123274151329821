/**
  * Flickity
  */
.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  position: relative;
  height: 100%;
  overflow: hidden;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

.flickity-prev-next-button {
  position: absolute;
  bottom: var(--ws);
  z-index: 99;
  width: 50%;
  height: 66.667vw;
  background: transparent;
  border: 0;
}

.flickity-prev-next-button.previous {
  left: 0;
  cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAAB4CAMAAAANIQqNAAAAsVBMVEX////MzMwAAADMzMwAAAAAAADMzMyKiooJCQkAAAAAAADMzMyfn58DAwPMzMwEBASWlpYAAAC7u7sAAADMzMwHBwcAAADMzMzMzMy4uLgEBAQGBgaioqIGBgYGBgZ8fHwAAACurq66urrMzMwFBQUGBgYAAAAaGhq2trYICAggICCLi4urq6sKCgpvb2+RkZHMzMwAAAAMDAwmJiZmZmYyMjIZGRlMTExycnI/Pz9ZWVmxabA6AAAAMXRSTlMAgIAQEDAw9dlQICDZ9FD19JDEcHCmQECQpsTm5rXW9mDWtWD299Cll8iYmMiX96XQJddDlAAAAX1JREFUeF7tmltOKlEUBVeDICio0IAIgoDvt+uc5qHzH9hN7gz8MPXRu8ZQya6VbP2CwUNLJF37USAn585TgSydegJp2DdtcUzs/a04jvrOVwKZOfWa9Rbge8QKsNsIZGFfkwKU9oEUoEULMOcFuIAFqC5JAe69LUgBOk5DgYxhAVa0AHfOhUA6TmuBDOzjU7bCf85qXeEhAFrhUeExw3asAHCFR4XHDKtYAeAKn8MVHhUeM6zeFR4VHgI8CeQ5KjxmGCxAfmlgSGaRKjtXHFKxtV8LDEnD5M6RQJoX9lgkl5W9Ekmx9V1LJG+0BqfH9rtIzn7srkim2ecnIlknL0VrMKi5Ble0Bs1e8kwk7Ru7IZLbvT2hNeizl/natAYHWoPRt90SyWbnOa7BAtegZDXY0hoMk+e17/To9Jhr/wNtFZ0enR5zrZe8rH2nR6fzc+2D73R7Qnd6PzrdZcw1vtMXeKeXdKffR6fHXMv0XFsnd2rf6flTKNOvP3hz+AfAaHw8WxbjyQAAAABJRU5ErkJggg=="), w-resize;
}

.flickity-prev-next-button.next {
  right: 0;
  cursor: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAAB4CAMAAAANIQqNAAAAsVBMVEX////MzMwAAADMzMwAAAAAAADMzMyKiorMzMwAAACfn58AAAAJCQkDAwPMzMyWlpYEBATMzMwAAADMzMwEBAQAAADMzMwHBwe7u7sAAAC4uLi6uroFBQUGBgaioqLMzMyurq4AAAAGBgYGBgZ8fHwGBgYaGhoAAAC2trYICAggICCLi4urq6sKCgpvb2+RkZHMzMwAAAAmJiYMDAwyMjJmZmYZGRlMTExycnI/Pz9ZWVn+nniSAAAAMXRSTlMAgIAQEDAw9SAg2VDZ9FD09ZBwcMSQQKbEQKa19ubmYNZg1rX296XQl8iYmMiX96XQmMmoHQAAAXpJREFUeF7tl2du4gAYBZ8BAzEmlNACJCEkZHt5LrT7H2wlbrDSSrOSvznD/JjRvyf9NBfKhz0QyaTw+E4kWem9SLrvdkskLyf7USTrwsOOQNpZ6VnDNXi40BpsjrQGz/Ybq8HZnuIapLQGT6wGI1qDRU1rkFQ+sBqsSue4Bktcg1dWg8JbVoNd6Vwk/Z7Ndvr9NTp9V3rPa4B3+gDv9Oj02DW+0+1Z4zs9Op3ftf+h0013Or9r0emVtym/a3SgLaPTo9Nj1z7fNIA7/YtuRKfHrrUwvhY3Dcwi1RyFXUsJxje7SsTRyV2uBLK0R21xvNr1Qhzplhfgu0B+2L2+OAb29V4cd2MXE4HsXe4EMncIsBbIzGXWFkfLfu+K49E+vYijM8QF8DMswJkUILUvD6QATz5uBPIGCzDlBahYARpe4VHhIUCzKzwqPATIBBIVHhv2ExWArvBhVHhsGCzACBWArvBD0ys8NqxIBJI3vML1q0ArXOr+/psK/wOSVHzE5QrRqwAAAABJRU5ErkJggg=="), e-resize;
}

.flickity-button-icon {
  display: none;
}

/**
  * Custom styles
  */

.work {
  padding: var(--ws);
}

.work__items {
  height: 100vh;
  height: calc(100 * var(--vh, 1vh));
}

.no-js .work__items {
  height: auto;
  margin-top: calc(var(--t-l) * 1vmin + var(--ws-l));
}

.work__item {
  width: 100%;
  box-sizing: border-box;
  padding: var(--ws);
}

.work__item__title {
  margin: 0 0 var(--ws);
  font-size: 1rem;
  font-style: italic;
  font-weight: 400;
}

.work__item__title.in-progress {
  visibility: hidden;
}

.work__item__image__wrapper {
  width: 100%;
  margin: 0 0 var(--ws);
}

.work__item__image__wrapper::after {
  position: absolute;
  bottom: calc(50% - 4px);
  left: calc(50% - 40px);
  z-index: -1;
  display: block;
  width: 80px;
  height: 8px;
  content: " ";
  background: linear-gradient(to right, rgba(247,49,49,1) 0%, rgba(245,168,14,1) 14%, rgba(245,245,14,1) 29%, rgba(126,245,14,1) 42%, rgba(14,245,226,1) 57%, rgba(33,26,240,1) 71%, rgba(229,26,240,1) 85%, rgba(230,39,39,1) 100%);
  background-size: 300% 100%;
  border-radius: 6px;
  animation: rotate-hue 1s infinite;
}

.work__item__image {
  display: block;
  width: 100%;
  height: auto;
  opacity: 0;
}

.no-js .work__item__image,
.work__item__image.flickity-lazyloaded {
  opacity: 1;
  transition: opacity 0.4s;
}

.work__item__image__caption {
  display: none;
}

@media (min-width: 960px) {
  .work {
    display: flex;
    justify-content: space-between;
    padding: 0;
  }

  .work__info {
    width: 300px;
    box-sizing: border-box;
    padding: var(--ws);
  }

  .work__title {
    margin: 0 0 var(--ws);
  }

  .work__item__title {
    font-size: 2rem;
  }

  .work__item__link {
    font-size: 1rem;
  }

  .work__items {
    max-width: 138vh;
    flex: 1;
    margin-right: var(--ws-l);
  }

  .work__item {
    position: relative;
  }

  .work__item__header {
    position: absolute;
    left: calc(-1 * (300px - var(--ws)));
    width: 300px;
  }

  [data-slideshow].flickity-enabled {
    height: 100vh;
    margin-top: 0;
  }

  .flickity-enabled [data-slide] {
    height: 100%;
    margin: 0;
  }

  .flickity-enabled .work__item__image {
    position: absolute;
    bottom: var(--ws);
  }

  .work__item__image__wrapper::after {
    bottom: calc(0.5 * 0.6667 * (100vw - 300px - var(--ws)));
    left: calc(50% - 80px);
    width: 160px;
  }

  .flickity-prev-next-button {
    height: calc(0.6667 * (100vw - 300px - var(--ws)));
  }

}
