html {
  font-family: miller-text, Georgia, "Times New Roman", Times, serif;
  font-size: 18px;
  line-height: 1.3;
}

@media (min-width: 750px) {
  html { font-size: 20px; }
}

.t-space {
  font-family: "Space Grotesk";
  font-weight: 700;
  letter-spacing: -0.04em;
}

.t-title {
  font-family: "Space Grotesk";
  font-size: calc(var(--t-l) * 1vmin);
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.04em;
}

h2,
h3 {
  font-family: "Space Grotesk";
  font-size: calc(var(--t-l) * 1vmin);
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.04em;
}

h4,
h5 {
  margin: 1.5em 0 1em;
  font-family: "Space Grotesk";
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.04em;
}
