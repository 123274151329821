@font-face {
  font-display: swap;
  font-family: "Space Grotesk";
  src:
    url("../fonts/SpaceGrotesk-Bold.woff2") format("woff2"),
    url("../fonts/SpaceGrotesk-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 700;
}

:root {
  --black: #181818;
  --darkgrey: #414141;
  --grey: #818181;
  --white: #fffef8;

  --ws: 15px;
  --ws-l: 20px;
  --ws-xl: 90px;
  --logo-height: 45; /* px, but unitless for use in calc */

  --t-l: 6.5; /* vmin, but unitless for use in calc */
}

@media (min-width: 960px) {
  :root {
    --ws: 30px;
    --ws-l: 40px;
    --ws-xl: 180px;
    --logo-height: 60; /* px, but unitless for use in calc */
  }
}
