.logo {
  position: absolute;
  top: var(--ws);
  left: var(--ws);
  z-index: 9;
}

.logo__image {
  display: block;
  width: auto;
  height: calc(var(--logo-height) * 1px);
  margin-bottom: calc(var(--logo-height) * 1px);
}

