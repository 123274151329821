.hidden {
  display: none !important;
}

.obf {
  unicode-bidi: bidi-override;
  direction: rtl;
}

.nobreak {
  white-space: nowrap;
}
