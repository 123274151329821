html {
  color: var(--black);
  background: var(--white);
}

.theme-negative {
  color: var(--white);
  background: var(--black);
}

a {
  position: relative;
  z-index: 1;
  display: inline-block;
  color: inherit;
  text-decoration: none;
  text-shadow: var(--white) 0 1px 0, var(--white) 0 -1px 0, var(--white) 0 -2px 0, var(--white) -1px 2px 0, var(--white) 1px 2px 0, var(--white) 0 3px 0;
}

.theme-negative a {
  text-shadow: var(--black) 0 1px 0, var(--black) 0 -1px 0, var(--black) 0 -2px 0, var(--black) -1px 2px 0, var(--black) 1px 2px 0, var(--black) 0 3px 0;
}

a::after {
  position: absolute;
  bottom: 0.1em;
  z-index: -1;
  display: block;
  width: 100%;
  height: 1px;
  content: " ";
  background-color: var(--black);
}

.theme-negative a::after {
  background-color: var(--white);
}

.no-touch a:hover::after {
  height: 2px;
  background: linear-gradient(to right, rgba(247,49,49,1) 0%, rgba(245,168,14,1) 14%, rgba(245,245,14,1) 29%, rgba(126,245,14,1) 42%, rgba(14,245,226,1) 57%, rgba(33,26,240,1) 71%, rgba(229,26,240,1) 85%, rgba(230,39,39,1) 100%);
  background-size: 300% 100%;
  animation: rotate-hue 1s infinite;
}

@keyframes rotate-hue {
  0% { background-position: 0 50%; }
  100% { background-position: 100% 50%; }
}

.ws-top {
  margin-top: var(--ws);
}

.ws-bottom {
  margin-bottom: var(--ws);
}
