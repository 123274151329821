.info {
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
  height: calc(100 * var(--vh, 1vh));
  box-sizing: border-box;
  padding: var(--ws);
  padding-right: var(--ws-l);
}

.info__contact,
.info__hire {
  width: 100%;
}

.info__hire ul {
  padding-left: 0;
}

.info__hire li {
  font-style: italic;
  list-style: none outside none;
}

.info__hire li::before {
  display: inline-block;
  width: 1.5em;
  height: 1em;
  vertical-align: middle;
  content: "";
  background-image: url(../images/arrow-right.svg);
  background-repeat: no-repeat;
  background-position: 0 50%;
  background-size: auto 0.75em;
}

.info__smallprint {
  align-self: flex-end;
  margin-bottom: 0;
  font-size: 0.7rem;
}

@media (min-width: 960px) {
  .info {
    align-items: flex-end;
  }

  .info__title,
  .info__smallprint {
    width: 100%;
  }

  .info__contact {
    flex-grow: 2;
  }

  .info__hire {
    flex-grow: 1;
  }

  .info__contact,
  .info__hire {
    font-size: 1.5rem;
  }

}
