.intro {
  position: relative;
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  height: calc(100 * var(--vh, 1vh));
  box-sizing: border-box;
  padding: var(--ws);
  padding-right: var(--ws-l);
}

.js .intro::after  {
  position: absolute;
  bottom: calc(50% - 4px);
  left: calc(50% - 40px);
  z-index: -1;
  display: block;
  width: 80px;
  height: 8px;
  content: " ";
  background: linear-gradient(to right, rgba(247,49,49,1) 0%, rgba(245,168,14,1) 14%, rgba(245,245,14,1) 29%, rgba(126,245,14,1) 42%, rgba(14,245,226,1) 57%, rgba(33,26,240,1) 71%, rgba(229,26,240,1) 85%, rgba(230,39,39,1) 100%);
  background-size: 300% 100%;
  border-radius: 6px;
  animation: rotate-hue 1s infinite;
}

.intro__text {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  flex-grow: 100;
  width: 100%;
  height: 100%;
  padding-top: calc(2 * var(--logo-height) * 1px);
  font-family: "Space Grotesk";
  font-size: calc(var(--t-l) * 1vmin);
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.04em;
}

.js .intro__text {
  opacity: 0;
}

.intro__text p {
  margin: 0;
}

.intro__canvas-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#intro__canvas {
  width: 100%;
  height: 100%;
}

.intro__mask {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 2;
  width: 100%;
  height: 100%;
  outline: 4px solid var(--white);
  outline-offset: -1px;
}

.js .intro__text.ready {
  opacity: 1;
  transition: opacity .5s 2s;
}
